
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { ChangeItemsAvailability, MenuCategory, MenuItem } from '@/interfaces';
import TurnOffDuration from './TurnOffDuration.vue';

@Component({ components: { TurnOffDuration } })
export default class MenuSwitcherCategory extends Vue {
  @Prop({ required: true }) readonly category!: MenuCategory;
  @PropSync('selectedItems', { default: () => [] }) readonly selected!: number[];

  categoryChecked = false;

  @Watch('selectedItems')
  onSelectedItems(val: number[]): void {
    if (!val.length) {
      this.categoryChecked = false;
    }
  }

  toggleItem(item: MenuItem, data: ChangeItemsAvailability): void {
    this.$emit('toggleItem', {
      id: item.id,
      state: data?.state || !item.active,
      until: data?.until,
    });
  }
}
