
  import { Component, Vue } from 'vue-property-decorator';
  import { TurnItemOffUntil } from '@/interfaces';

  @Component
  export default class TurnOffDuration extends Vue {
    get turnItemOffUntil(): typeof TurnItemOffUntil {
      return TurnItemOffUntil;
    }
  }
