
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { WorkingHour } from '@/interfaces';
  import { UpdateHoursPayload } from '@/store/interfaces';
  import { ConceptModel } from '@/models';
  import WorkingHoursModal from '@/components/modals/WorkingHours.vue';

  const conceptsStore = namespace('concepts');

  @Component({ components: { WorkingHoursModal } })
  export default class WorkingHours extends Vue {
    @Prop({ required: true }) readonly conceptId!: number;

    showModal = false;

    get weekdays(): string[] {
      return this.$t('restaurants.days') as unknown as string[];
    }

    @conceptsStore.State
    conceptsForUser!: ConceptModel[];

    get workingHours(): WorkingHour[] | null {
      return this.conceptsForUser.find(concept => concept.id === this.conceptId)?.whs || null;
    }

    @conceptsStore.Action
    updateHours!: (data: UpdateHoursPayload) => Promise<boolean>;

    submit(data: WorkingHour[]): void {
      const { conceptId } = this;
      this.updateHours({ conceptId, data });
    }
  }
