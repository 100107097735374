
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { ConceptModel } from '@/models';

  import MenuSwitcher from '@/components/MenuSwitcher/index.vue';
  import WorkingHours from '@/components/WorkingHours.vue';
  import SynchronizeBtn from '@/components/buttons/SynchronizeBtn.vue';
  import ConceptStatus from '@/components/ConceptStatus.vue';
  import CheckAccess from '@/components/CheckAccess.vue';

  const conceptsStore = namespace('concepts');

  @Component({ components: { MenuSwitcher, WorkingHours, SynchronizeBtn, ConceptStatus, CheckAccess } })
  export default class ConceptPage extends Vue {
    editConceptModal = false;

    @conceptsStore.State
    conceptsForUser!: ConceptModel[];

    get conceptId(): number {
      return +this.$route.params.id;
    }

    get openPanel(): number {
      return this.$route.query?.panel ? +this.$route.query?.panel : 0; 
    }

    get concept(): ConceptModel | null {
      return this.conceptsForUser.find(concept => concept.id === this.conceptId) || null;
    }

    get hasAnyIntegration(): boolean {
      return Boolean(this.concept && (
        this.concept.ue_storeId || this.concept.own_storeId || this.concept.glovo_storeId || this.concept.bolt_storeId
        || this.concept.wolt_storeId
      ));
    }
  }
