
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { ChangeItemsAvailability, ToggleItem, TurnItemOffUntil } from '@/interfaces';
  import { ToggleMenuItemsPayload } from '@/store/interfaces';
  import { MenuCategoryModel, MenuModel } from '@/models';

  import ChangeMultipleButtons from './ChangeMultipleButtons.vue';
  import Category from './Category.vue';

  const conceptsStore = namespace('concepts');

  @Component({ components: { ChangeMultipleButtons, Category } })
  export default class MenuSwitcher extends Vue {
    @Prop({ required: true }) readonly conceptId!: number;

    menu: MenuModel | null = null;
    selectedItems: number[] = [];

    mounted(): void {
      this.loadMenu();
    }

    get categories(): MenuCategoryModel[] {
      return this.menu?.getCategories() || [];
    }

    @conceptsStore.Action('getConceptMenu')
    getMenu!: (id: number) => Promise<MenuModel>;

    @conceptsStore.Action
    toggleMenuItems!: (data: ToggleMenuItemsPayload) => Promise<boolean>;

    async loadMenu(): Promise<void> {
      this.menu = await this.getMenu(this.conceptId);
    }

    toggleItemsFromCategory({ id: categoryId, state }: ToggleItem): void {
      const itemsId = this.menu?.getCategories()
        ?.find(item => item.id === categoryId)
        ?.items.map(item => item.id);

      if (!itemsId?.length) {
        return;
      }

      if (state) {
        this.selectedItems = [
          ...this.selectedItems,
          ...itemsId,
        ];
      } else {
        this.selectedItems = this.selectedItems.filter(itemId => !itemsId.includes(itemId));
      }
    }

    toggleItem({ id: itemId, state, until }: ToggleItem): void {
      return this.changeProductsAvailability([itemId], state, until)
    }

    changeSelected({ state, until }: ChangeItemsAvailability): void {
      this.changeProductsAvailability(this.selectedItems, state, until);
      this.selectedItems = [];
    }

    changeProductsAvailability(itemsId: number[], state: boolean, until?: TurnItemOffUntil): void {
      itemsId = itemsId.filter(itemId => this.menu?.productsAvailability[itemId] !== state);

      if (!itemsId.length || !this.menu) {
        return;
      }

      const affected = this.menu.updateItemsAvailability(itemsId, { state, until });
      this.toggleMenuItems({
        conceptId: this.conceptId,
        itemsId: affected,
        status: state,
        until,
      }).then((res) => {
        if (!res) {
          this.menu?.updateItemsAvailability(itemsId, { state: !res, until: until });
        }
      }); 
    }
  }
